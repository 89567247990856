.tabbed-page-container {
    width: 100%;
    max-width: 500px; /* Default maximum width */
    margin: auto;
 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    border-radius: 10px; /* Rounded corners for the container */
    background-color: #fff; /* Clean white background */
}

/* Responsive for max width of 400px */
@media (max-width: 400px) {
    .tabbed-page-container {
        max-width: 400px; /* Adjust max width for smaller screens */
    }
}

/* Responsive for max width of 300px */
@media (max-width: 300px) {
    .tabbed-page-container {
        max-width: 300px; /* Further adjust for very small screens */
    }
}

.tabs {
    display: flex;
    justify-content: space-between; /* Space tabs evenly */
    margin-bottom: 1px; /* Space between tabs and content */
}

.tab-list {
    display: flex; /* Align items in a row */
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    width: 100%; /* Ensure it takes full width */
}

.tab-link {
    font-size: 0.9rem;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0; /* Remove margin to allow spacing through justify-content */
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px; /* Rounded corners for the tabs */
    text-align: center; /* Center text inside the tabs */
    flex: 1; /* Allow tabs to grow equally */
}

.tab-link.active {
    color: green; /* Text color for active tab */
    font-weight: bold; /* Bold font for active tab */
}

.tab-link:hover {
    background-color: transparent; /* Hover effect */
    color: rgb(98, 230, 10); /* Change text color on hover */
}
