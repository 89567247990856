/* Profile.css */
.profile-container {
  max-width: 500px;
  margin: 0 auto;

  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.profile-card {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-details {
  text-align: start;
}

.profile-details h3 {
  font-size: 24px;
  color: #333;
}

.profile-details p {
  margin: 10px 0;
  font-size: 16px;
  color: #666;
}

.profile-edit h4 {
  margin-top: 20px;
  font-size: 20px;
  color: #333;
  text-align: left;
}

.profile-edit label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.profile-edit input,
.profile-edit select {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
}

.profile-edit input:focus,
.profile-edit select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.button-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.clear-profile-button {
  background-color: #dc3545;
}

.clear-profile-button:hover {
  background-color: #c82333;
}

.update-location-button {
  background-color: #28a745;
}

.update-location-button:hover {
  background-color: #218838;
}

.no-profile {
  text-align: center;
  font-size: 16px;
  color: #999;
}
