.app-container-rest {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: Arial, sans-serif;
  max-width: 500px; /* Limit max width */
  margin: 0 auto; /* Center the container */
  background-color: #fff; /* White background for the container */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  position: relative; /* Necessary for absolute positioning of child elements */
}



.search-input-rest {
  width: 90%; /* Full width of its container */
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px; /* Larger font size */
}

.restaurant-list {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  width: 100%; /* Full width */
}

.restaurant-card {
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  max-width: 450px; /* Limit width to 450px */
  margin-bottom: 20px; /* Space between cards */
  background-color: #fefefe; /* Slightly off-white for cards */
  transition: transform 0.2s; /* Smooth hover effect */
}

.restaurant-card:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.restaurant-card h2 {
  margin: 0 0 10px; /* Space below the title */
  font-size: 20px; /* Title size */
  color: #333; /* Dark text color */
}

.restaurant-card p {
  margin: 5px 0; /* Space between paragraphs */
  color: #555; /* Darker text for contrast */
}

@media (max-width: 500px) {
  .search-input {
    width: 100%; /* Full width of the container */
  }
}

@media (max-width: 480px) {
  .search-input {
    width: 100%; /* Full width on small screens */
  }
}


.location-message {
  text-align: center;
  padding: 30px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  max-width: 400px;
  margin: 50px auto;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.location-message h2 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: bold;
}

.location-message p {
  color: #555;
  margin-bottom: 20px;
  font-size: 1em;
}

.refresh-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.refresh-button:hover {
  background-color: #0056b3;
}
.centered-message {
  position: fixed; /* Fixed positioning to keep it in view */
  top: 50%; /* Move it down 50% from the top */
  left: 50%; /* Move it right 50% from the left */
  transform: translate(-50%, -50%); /* Center it by moving it back 50% of its own width and height */
  z-index: 1000; /* Ensure it's above other content */
  
  padding: 20px; /* Optional: some padding */
 
  text-align: center; /* Center the text */
}
