/* Bottom navigation bar container */
.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 50%; /* Start from the center of the viewport */
  transform: translateX(-50%); /* Center the element horizontally */
  width: 100%;
  max-width: 500px; /* Set maximum width */
  background-color: #ffffff;
  /* border-top: 1px solid #e0e0e0; */
  display: flex;
 
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px; /* Added padding for left and right margins */
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Individual button link */
.button-link {
  text-decoration: none;
  color: #555555; /* Subtle text color for better readability */
  font-size: 0.75em; /* Reduced font size */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease, transform 0.3s ease;
}

.button-link:hover {
  color: #007bff;
  transform: scale(1.1);
}

/* Active link styling */
.button-link.active {
  color: #00ff00;
}

/* Icon styling */
.icon {
  font-size: 1.2em; /* Smaller icon size */
  margin-bottom: 3px; /* Reduced spacing between icon and text */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .icon {
    font-size: 1em; /* Even smaller icon size for smaller screens */
  }

  .button-link span {
    font-size: 0.7em; /* Smaller text size for smaller screens */
  }
}
