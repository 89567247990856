/* Container for the entire app */
.app-container-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
}

/* Search and cart button container */
.search-cart-container1-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-radius: 40px;
    width: 100%;
}

/* Loading container */
.loading-container-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: #f5f5f5; /* Optional: background color */
    font-size: 1.0em;
    font-weight: bold;
    color: #333333; /* Text color */
  }
  
/* Search input styling */
.search-input-detail {
    width: 70%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    margin-left: 5px;
    transition: box-shadow 0.3s;
}

.search-input:focus {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    border-color: #007bff;
}

/* Cart button styling */
.cart-button-detail {
    padding: 12px 20px;
    background-color: #46a80d;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.cart-button:hover {
    background-color: #218838;
    transform: translateY(-3px);
}

.cart-button-nearby-detail {
    position: relative;
    background-color: #28a745;
    /* color: green; */
    color: white;
    border: none;
    height: 32px;
    border-radius: 15px;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cart-button-nearby-detail:hover {
    background-color: #28a745;
}

.cart-badge-detail {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius:20px;
    padding: 4px 8px;
    width: 10px;
    width: 10px;
    height: inherit;
    font-size: 12px;
    font-weight: bold;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Product grid with 3 columns */
.product-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    width: 100%;
    margin-bottom: 20px;
}

/* Individual product card styling */
.product-card-detail {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.product-card-detail:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}


/* Responsive design: Always keep 3 columns */
@media (max-width: 500px) {
    .app-container {
        max-width: 500px;
    }
    .product-list {
        grid-template-columns: repeat(3, 1fr); /* Force 3 columns even on medium screens */
    }.search-input-detail{
        margin-right: 5px;
    }
}

@media (max-width: 450px) {
    .app-container {
        max-width: 450px;
    }
    .product-list {
        grid-template-columns: repeat(3, 1fr); /* Keep 3 columns on smaller screens */
        gap: 8px; /* Reduce gap between columns for smaller screens */
    }.search-input-detail{
        margin-right: 5px;
    }
    .cart-button-nearby-detail {
        position: relative;
        background-color: #28a745;
        /* color: green; */
        color: white;
        border: none;
        border-radius: 15px;
        padding: 10px 15px;
        font-size: 9px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}

@media (max-width: 350px) {
    .app-container {
        max-width: 350px;
    }
    .product-list {
        grid-template-columns: repeat(3, 1fr); /* Maintain 3 columns for very small screens */
        gap: 4px; /* Further reduce gap for very small screens */
    }.search-input-detail{
        margin-right: 5px;
    }
}
/* Product image styling */
.product-image-detail {
    width: 100%;
    height: auto;
    height: 100px;
    margin-bottom: 12px;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.product-image-detail:hover {
    transform: scale(1.05);
}

/* Product name styling */
.product-name-detail {
    font-size: 10px;
    margin: 8px 0;
    font-weight: bold;
}

/* Product price styling */
.product-price-detail {
    font-size: 10px;
    color: #333;
    margin-bottom: 10px;
}

/* Quantity controls */
.quantity-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.quantity-controls button {
    background-color: #46a80d;
    color: white;
    border: none;
    font-size: smaller;
    border-radius: 20px;
    padding: 8px 12px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.quantity-controls button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
}

/* Add to cart button */
.add-to-cart-detail-button {
    background-color: #28a745;
    color: green;
    border: none;
    font-size: small;
    border-radius: 2px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 10px;
}

.add-to-cart-button:hover {
    background-color: #218838;
    transform: scale(1.1);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .product-list {
        grid-template-columns: repeat(3, 1fr); /* 2 columns for medium screens */
    }
}

@media (min-width: 300px) and (max-width: 400px) {
    .product-list {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for widths between 300px and 400px */
    }
}


/* Cart Sidebar */
.cart-sidebar {
    width: 400px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 0;
    top: 0;
    width: 75%;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
}
/* Responsive design for Cart Sidebar */
@media (max-width: 500px) {
    .cart-sidebar {
        width: 75%; /* Cover 75% of the .app-container width */
    }
}
/* Responsive design for Cart Sidebar */
@media (max-width: 400px) {
    .cart-sidebar {
        width: 75%; /* Cover 75% of the .app-container width */
    }
}
.cart-sidebar.slide-in {
    transform: translateX(0); /* Slide in */
}

/* Close button for cart */
.close-button {
    background-color: transparent;
    border: none;
    color: #ff4d4d;
    font-size: 24px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: color 0.3s;
}

.close-button:hover {
    color: #ff0000;
}

/* Cart item styles */
.cart-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.cart-item:last-child {
    border-bottom: none;
}

.cart-item span {
    font-size: 16px;
    color: #333;
}

/* Remove button for cart items */
.remove-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-button:hover {
    background-color: #c82333;
}

/* Order button */
.order-button-detail {
    background-color: #2ccf52;
    color: white;
    padding: 15px 20px;
    border-radius: 20px;
    font-size: 18px;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.order-button:hover {
    background-color: #218838;
}

/* this id added for circular */

/* Spinner styles */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: rgb(3, 192, 12);
    animation: spin 1s ease infinite;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .fee-details {
    border: 1px solid #e0e0e0;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 400px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.fee-details h3 {
    text-align: center;
    color: #333;
    margin-bottom: 15px;
}

.fee-details p {
    font-size: 16px;
    color: #555;
    margin: 10px 0;
}

.fee-details p span {
    font-weight: bold;
    color: #007BFF; /* Primary color for highlighted values */
}

.delivery-fee {
    color: #ff9900; /* Orange for delivery fee */
}

.service-fee {
    color: #dc3545; /* Red for service fee */
}

.total-price {
    font-weight: bold;
    color: #28a745; /* Green for total price */
}

.distance {
    color: #666; /* Gray for distance information */
}

.fee-details {
    transition: all 0.3s ease;
}

.fee-details:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

  