.internet-status {
    background-color: #ffe6e6; /* Light red background */
    border: 1px solid #ff0000; /* Red border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Spacing inside the div */
    max-width: 500px; /* Restricting width for a clean layout */
    margin: 20px auto; /* Centering the div */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adding subtle shadow */
  }
  
  .internet-status p {
    color: #ffd900; /* Red text */
    text-align: center; /* Centering the text */
    font-weight: bold; /* Making the text bold */
    font-size: 16px; /* Adjusting the font size */
  }
  