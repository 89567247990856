.app-container-nearby {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: Arial, sans-serif;
  max-width: 500px; /* Limit max width */
  margin: 0 auto; /* Center the container */
  background-color: #fff; /* White background for the container */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  position: relative; /* Necessary for absolute positioning of child elements */
}

.search-cart-container-nearby {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    flex-wrap: nowrap; /* Prevent wrapping */
}

.search-input-nearby {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 60%;
}

.cart-button-nearby {
    padding: 10px 20px;
    background-color: #1b9610;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
.cart-button-nearby {
    position: relative;
    background-color: #4CAF50; /* A pleasing green */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cart-button-nearby:hover {
    background-color: #45a049; /* Darker shade for hover effect */
}

.cart-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .app-container-nearby{
        max-width: 600px;
    }
    .search-cart-container-nearby {
        max-width: 600px;
        flex-direction: row; /* Stack elements vertically */
        align-items: stretch; /* Ensure full width */
    }

    .search-input-nearby {
        width: 70%; /* Full width on smaller screens */
        margin-bottom: 10px; /* Space between elements */
    }

    .cart-button-nearby {
        width: 20%; /* Full width on smaller screens */
        padding: 8px 12px;
        border: none;
        border-radius: 5px;
        background-color: #4caf50;
        color: white;
        cursor: pointer;
        margin-left: 5px;
        margin-bottom: 10px; /* Space between elements */
    }
}

/* Media query for smaller screens */
@media (max-width: 500px) {
    .app-container-nearby{
        max-width: 500px;
        
    }
    .search-cart-container-nearby {
        max-width: 500px;
        flex-direction: row; /* Stack elements vertically */
        align-items: stretch; /* Ensure full width */
    }

    .search-input-nearby {
        width: 70%; /* Full width on smaller screens */
        margin-bottom: 10px; /* Space between elements */
    }

    .cart-button-nearby {
        width: 20%; /* Full width on smaller screens */
        padding: 8px 12px;
        border: none;
        border-radius: 5px;
        background-color: #4caf50;
        color: white;
        cursor: pointer;
        margin-left: 5px;
        margin-bottom: 10px; /* Space between elements */
    }
}
/* Media query for smaller screens */
@media (max-width: 400px) {
    .app-container-nearby{
        max-width: 400pxpx;
    }
    .search-cart-container-nearby {
        max-width: 400px;
        flex-direction: row; /* Stack elements vertically */
        align-items: stretch; /* Ensure full width */
    }

    .search-input-nearby {
        width: 70%; /* Full width on smaller screens */
        margin-bottom: 10px; /* Space between elements */
    }

    .cart-button-nearby {
        width: 20%; /* Full width on smaller screens */
        padding: 8px 12px;
        border: none;
        border-radius: 5px;
        background-color: #4caf50;
        color: white;
        cursor: pointer;
        margin-left: 5px;
        margin-bottom: 10px; /* Space between elements */
    }
}

.product-list-nearby {
    list-style-type: none;
    padding: 0;
}

.list-item-nearby {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 10px;
    transition: transform 0.3s;
}

.list-item-nearby:hover {
    transform: translateY(-2px);
}

.list-item-content {
    display: flex;
    justify-content: space-between; /* Space between avatar and product info */
}

.list-item-avatar {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.avatar {
    width: 100%;
    height: 100%;
    border-radius: 20px; /* Circular image */
}

.list-item-text {
    flex: 1;
   
   
   
}



.product-name {
    font-size: 1.0em; /* Increased font size for better visibility */
    margin: 0;
    font-weight: bold; /* Bold font for emphasis */
    color: #333; /* Dark color for readability */
}

.product-price {
    color: #888; /* Gray color for price */
    font-size: 1.1em; /* Slightly larger font for price */
    margin: 5px 0; /* Add margin for spacing */
}

.product-from-hotel {
    font-size: 0.9em; /* Smaller font size for the 'From' text */
    color: #555; /* Lighter shade for differentiation */
    margin-top: 5px; /* Space above this line */
}

/* Responsive styles */
@media (max-width: 768px) {
    .product-name {
        font-size: 1.3em; /* Adjust size on smaller screens */
    }
    
    .product-price {
        font-size: 1em; /* Adjust size on smaller screens */
    }

    .product-from-hotel {
        font-size: 0.8em; /* Adjust size on smaller screens */
    }
}
/* Responsive styles */
@media (max-width: 380px) {
    .product-name {
        font-size: 1.0em; /* Adjust size on smaller screens */
    }
    
    .product-price {
        font-size: 0.7em; /* Adjust size on smaller screens */
    }

    .product-from-hotel {
        font-size: 0.4em; /* Adjust size on smaller screens */
    }
   
}


.quantity-controls {
    display: flex;
    align-items: center;
}

.control-button,
.add-to-cart-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
    margin-left: 5px;
}

.remove-button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    background-color: #f44336;
    color: white;
    cursor: pointer;
}
.cart-sidebar-nearby {
    position: absolute;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: #ffffff;
    border-left: 1px solid #ddd;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Enable scrolling if content overflows */
    transition: transform 0.3s ease;
    z-index: 10; /* Ensure sidebar is on top */
    padding: 20px; /* Add padding for better spacing */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

.slide-in {
    transform: translateX(0); /* Show sidebar */
}

.slide-out {
    transform: translateX(100%); /* Hide sidebar */
}

.close-button {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #ff4d4d; /* Red color for close button */
    position: absolute; /* Position at the top right */
    top: 15px;
    right: 15px;
    transition: color 0.3s; /* Transition for hover effect */
}

.close-button:hover {
    color: #ff0000; /* Darker red on hover */
}

.order-button {
    padding: 12px 20px; /* Increased padding for a better touch target */
    background-color: #33f321; /* Primary color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Improved font size */
    margin-top: 20px; /* Space above the button */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions for hover effects */
}

.order-button:hover {
    background-color: #38d219; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Additional styles for better content structure */
.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items vertically centered */
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.cart-item:last-child {
    border-bottom: none; /* No border on the last item */
}

.cart-item span {
    font-size: 16px;
    color: #333; /* Darker text color */
}

.remove-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-button:hover {
    background-color: #c82333; /* Darker red on hover */
}

/* Adding some responsive design */
@media (max-width: 600px) {
    .cart-sidebar-nearby {
        width: 75%; /* Full width on smaller screens */
    }

    .order-button {
        padding: 10px; /* Adjust button padding for smaller screens */
        font-size: 14px; /* Smaller font size for mobile */
    }

    .close-button {
        font-size: 18px; /* Slightly smaller close button */
    }
     .control-button,
     .add-to-cart-button {
         padding: 4px 7px;
         border: none;
         border-radius: 5px;
         background-color: #4caf50;
         color: white;
         cursor: pointer;
         margin-left: 3px;
   }
}
