.help-container {
  padding: 5px;
 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  max-height: 100%;
}

.help-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #007bff;
}

.help-loading,
.help-error,
.no-helpers {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  color: #dc3545;
}

.helpers-list {
  list-style: none;
  padding: 0;
}

.helper-card {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.helper-name {
  font-size: 20px;
  font-weight: bold;
  color: #343a40;
}

.helper-phone {
  color: #007bff;
  text-decoration: none;
}

.copy-button {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #218838;
}

.helper-card strong {
  font-weight: 600;
  color: #6c757d;
}

@media (max-width: 600px) {
  .help-container {
    padding: 15px;
  }

  .helper-card {
    padding: 10px;
  }

  .help-title {
    font-size: 20px;
  }

  .helper-name {
    font-size: 18px;
  }
}
