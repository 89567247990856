.form-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: Arial, sans-serif;
  max-width: 500px; /* Limit max width */
  margin: 0 auto; /* Center the container */
  background-color: #fff; /* White background for the container */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  position: relative; /* Necessary for absolute positioning of child elements */

}

.profile-form {
  display: flex;
  flex-direction: column;
}

.profile-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.profile-form label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.profile-form input,
.profile-form select {
  padding: 12px; /* Increased padding for better touch target */
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners for input and select */
  transition: border-color 0.3s ease; /* Smooth transition for focus effect */
}

.profile-form input:focus,
.profile-form select:focus {
  border-color: #007bff; /* Blue border on focus */
  outline: none; /* Remove default outline */
}

.profile-form button {
  padding: 12px; /* Increased padding for button */
  background-color: #28a745; /* Green background */
  color: white;
  border: none;
  border-radius: 8px; /* Rounded corners for button */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.profile-form button:hover {
  background-color: #218838; /* Darker green for hover */
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}
