/* showMyOrders.css */
.my-orders-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #ffffff;
  max-height: 100%;
}

.my-order-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.order-card {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  cursor: pointer;

}


.order-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.order-name {
  font-size: 1.5rem;
  color: #6dd153;
  margin-bottom: 10px;
}

.order-status {
  font-weight: bold;
  color: #6c757d;
  margin-bottom: 10px;
}

.order-items-my {
  margin-top: 20px;
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 6px;
}

.order-item-my {
  padding: 10px 0;
  border-bottom: 1px solid #e9ecef;
}

.order-item:last-child {
  border-bottom: none;
}

.order-item-my h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #343a40;
}

.order-item-my p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #495057;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

p {
  margin: 5px 0;
  font-size: 0.9rem;
}

h3 {
  font-size: 1.3rem;
  color: #343a40;
  margin-bottom: 10px;
}
.rejection-notification {
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Darker red text */
  border: 1px solid #f5c6cb; /* Border color */
  padding: 10px;
  border-radius: 5px; /* Rounded corners */
  margin-top: 10px; /* Space above the notification */
}

.rejection-message {
  margin: 0; /* Remove default margin */
}

.rejection-detail {
  margin-top: 5px; /* Add space above the detail message */
}
.acceptance-notification {
  background-color: #d4edda; /* Light green background */
  border: 1px solid #c3e6cb; /* Green border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Padding for spacing */
  margin-top: 10px; /* Space above the notification */
}

.acceptance-message {
  color: #155724; /* Dark green text */
  font-weight: bold; /* Bold text */
}

.acceptance-detail {
  color: #155724; /* Dark green text */
}

