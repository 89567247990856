/* Product Home Page Styles */

.product-home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    min-height: 100vh;
    justify-content: flex-start; /* Align items to the top */
    width: 100%;
  }
  
  .search-filter-bar {
    display: flex;
    justify-content: center;
    gap: 10px;
    /* justify-content: flex-start; */
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
  }
  
  .search-filter-bar input {
    padding: 10px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 300px;
    box-sizing: border-box;
  }
  
  .search-filter-bar button {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-filter-bar button:hover {
    background-color: #0056b3;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
    gap: 20px;
    margin-top: 20px;
    max-width: 500px;  /* Maximum width of 500px */
    width: 100%;      /* Full width */
    margin-left: auto; /* Centering the grid */
    margin-right: auto; /* Centering the grid */
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .product-card img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-card h5 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .product-card p {
    font-size: 16px;
    color: #333;
    margin-top: 5px;
  }
  
  .product-card .distance {
    font-size: 14px;
    color: #888;
    margin-top: 5px;
  }
  
  .product-card .price {
    font-size: 16px;
    font-weight: bold;
    color: #007bff;
  }
  
  .product-card .price::before {
    content: "Price: ";
    font-weight: normal;
  }
  
  .product-card .distance::before {
    content: "Distance: ";
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .search-filter-bar input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .search-filter-bar button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .product-card img {
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .product-card img:hover {
    transform: scale(1.05);
  }
  
  .filter-button {
    padding: 10px 15px;
    background-color: #007bff;
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .filter-button:hover {
    background-color: #0056b3;
  }
  
  .filter-button:active {
    background-color: #004085;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .product-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .search-filter-bar {
      flex-direction: column;
    }
  
    .search-filter-bar input {
      width: 80%;
    }
  
    .search-filter-bar button {
      width: 80%;
      margin-top: 10px;
    }
  
    .product-grid {
      grid-template-columns: 1fr 1fr; /* 2 columns on smaller screens */
      gap: 15px;
      margin-top: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .product-grid {
      grid-template-columns: 1fr; /* 1 column on very small screens */
    }
  
    .search-filter-bar input {
      width: 90%;
    }
  
    .search-filter-bar button {
      width: 90%;
      margin-top: 10px;
    }
  
    .product-grid {
      margin-top: 10px; /* Ensure gap between search bar and grid */
    }
  }
  
  @media (max-width: 400px) {
    .product-grid {
      grid-template-columns: 1fr; /* 1 column on very small screens */
      gap: 10px;
    }
  
    .search-filter-bar input {
      width: 95%;
    }
  
    .search-filter-bar button {
      width: 95%;
      margin-top: 10px;
    }
  }
  